import React from 'react';

function MyBio(){
        return (
        <div>
        <p style={{fontSize:'2em'}}><b>Biography</b></p>
         <p style={{paddingBottom:'5%', marginLeft:'5%', marginRight:'5%'}}>
                Ninth Degree are a <b>5-piece Rock band from Edinburgh, Scotland.</b> Founded in 2019, Ninth Degree are unafraid to do things a bit differently.
                The band has developed a strong DIY work ethos, producing all their own material. 
                With <b>3 lead vocalists</b> creating harmonies not usually seen in similar music, <b>2 lead guitarists</b> and a love for a variety of styles and genres -  
                Ninth Degree pride themselves on having a unique take on the Modern Rock sound.


            <br></br><br></br>

                Releasing their debut single in 2019 <a href="https://open.spotify.com/album/370kPkuYzcKvzg5rdoNJgq" style={{color: "white"}}><b> "Smile"</b></a> 
                (along with the B-Side "Breathe") Ninth Degree took Scotland by storm. Playing shows up and down the country with great bands such as RumRunners, Stay For Tomorrow,
                Primes and Ross Arthur.
           
           
            <br></br><br></br>

               Throughout 2020 Ninth Degree have produced a series of 
               <a href="https://youtube.com/watch?v=ZtjxfeoL_-w&list=PLhiELbzgIWmz8lKUgl0MCiMBSVYzInLmQ" style={{color: "white"}}><b> Lockdown Sessions </b></a> 
               on YouTube, performing songs by artists like Rush, Catfish and the Bottlemen, Whitesnake and Queen. Along with recording a 
               <a href="https://open.spotify.com/album/6ZzHOhON6I6jl2D5dr1Vf7?si=Yz-gJHp2TMKo3Dl4fpemQg" style={{color: "white"}}><b> “Live in Lockdown” </b></a>  
               <a href="https://www.youtube.com/watch?v=i_tt5QEotu0" style={{color: "white"}}><b> [video] </b></a> album at Bannerman’s Bar Edinburgh.


            <br></br><br></br>

                 In 2021 they released their second studio single <a href="https://linktr.ee/ndfadeaway" style={{color: "white"}}><b> “Fade Away”</b></a>, 
                 and were able to perform live in front of an audience for the first time since the start of the pandemic. 
                 
                 The band are looking forward to being able to make waves in 2022, starting off the year by releasing their third studio single
                 <a href="https://linktr.ee/NDTrigger" style={{color: "white"}}><b> “Trigger”</b></a>, with plans for an EP to follow in the coming months.
         </p>
         </div>
        );
}

export default MyBio;