import React from 'react';
import MobLogo from './ND-Trigger-Artwork-1000x1000.jpg';
import TabLogo from './ND-Trigger-Artwork-1450x1450.jpg';
import DeskLogo from './ND-Trigger-Artwork-Hires.jpg';
import { Table } from 'react-bootstrap';
import './event.css'
import MobileLinks from './MobileLinks';
function HomeImage() {

  return (
    <div style={{backgroundColor:'#1f1f1f'}}>
    <div id='mobile'>
    <Table borderless hover variant="dark" style={{ margin: 'auto', width: '100%', backgroundColor:'#1f1f1f'}}>
            <tbody>
            <tr style={{paddingBottom:0, marginBottom:0}}>
                <td style={{paddingBottom:0, marginBottom:0}}><p style={{fontSize:'1.75em'}}>3RD STUDIO SINGLE <b>"TRIGGER" </b> </p> <p style={{fontSize:'0.9em'}}> Streaming on all major platforms now!!</p>
                 <p style={{fontSize:'0.65em'}}> <i> “Trigger” hits hard from the start with huge guitars and powerful vocals keeping a strong energy throughout. The track was written by Cameron and Hamish and has been a fan favourite from the start.
<br></br> <br></br>The recording started with a drum track recorded live as part of their “Live in Lockdown” album with the rest of the elements recorded remotely. The tracks were mixed, and mastered by Cameron.
This resulted in a studio quality track with the feel of a live performance. </i></p></td>
                </tr>
                <tr>
                <td style={{width:'25vw'}}> <div style={{position: 'relative', textAlign: 'left', color: 'white'}}>
                    <a href="https://tiny.cc/NDSpotify"> <img id='artwork' src={MobLogo} style={{width: '100%'}} alt='Ninth Degree Cover'  /> </a>
                   </div>
                </td>
                </tr>
            </tbody>
    </Table>
    </div>
    <div id='tablet'>
    <Table borderless hover variant="dark" style={{ width: '100%', backgroundColor:'#1f1f1f'}}>
            <tbody>
            <tr style={{paddingBottom:0, marginBottom:0}}>
                <td style={{paddingBottom:0, marginBottom:0}}><MobileLinks/><p style={{fontSize:'1.4=2em'}}>3RD STUDIO SINGLE <b>"TRIGGER"</b> </p> <p style={{fontSize:'0.7em'}}> Streaming on all major platforms now!</p>
                 <p style={{fontSize:'0.70em'}}> <i>“Trigger” hits hard from the start with huge guitars and powerful vocals keeping a strong energy throughout. The track was written by Cameron and Hamish and has been a fan favourite from the start.
<br></br> <br></br>The recording started with a drum track recorded live as part of their “Live in Lockdown” album with the rest of the elements recorded remotely. The tracks were mixed, and mastered by Cameron.
This resulted in a studio quality track with the feel of a live performance.
</i></p>
                 </td>
                <td style={{width:'40vw'}}> <div style={{position: 'relative', textAlign: 'left', color: 'white'}}>
                    <a href="https://tiny.cc/NDSpotify"> <img id='artwork' src={TabLogo} style={{width: '100%'}} alt='Ninth Degree Cover'  /> </a>
                   </div>
                </td>
                </tr>
            </tbody>
    </Table>
    </div>
    <div id='bigtablet'>
    <Table borderless hover variant="dark" style={{ width: '100%', backgroundColor:'#1f1f1f'}}>
           <tbody>
            <tr style={{paddingBottom:0, marginBottom:0}}>
                <td style={{paddingBottom:0, marginBottom:0}}><MobileLinks/><p style={{fontSize:'2.3em'}}>3RD STUDIO SINGLE <b>"TRIGGER" </b> </p> <p style={{fontSize:'1.3em'}}> Streaming on all major platforms now!</p>
                <p style={{fontSize:'0.90em'}}> <i>“Trigger” hits hard from the start with huge guitars and powerful vocals keeping a strong energy throughout. The track was written by Cameron and Hamish and has been a fan favourite from the start.
<br></br> <br></br>The recording started with a drum track recorded live as part of their “Live in Lockdown” album with the rest of the elements recorded remotely. The tracks were mixed, and mastered by Cameron.
This resulted in a studio quality track with the feel of a live performance.
</i></p>
                 </td>
                <td style={{width:'40vw'}}> <div style={{position: 'relative', textAlign: 'left', color: 'white'}}>
                    <a href="https://tiny.cc/NDSpotify"> <img id='artwork' src={TabLogo} style={{width: '100%'}} alt='Ninth Degree Cover'  /> </a>
                   </div>
                </td>
                </tr>
            </tbody>
    </Table>
    </div>
    <div id='desktop'>
    <Table borderless variant="dark" style={{ paddingTop:0, margin: 'auto', width: '100%', backgroundColor:'#1f1f1f'}}>
            <tbody>

            <tr style={{paddingBottom:0, marginBottom:0}}>

                <td style={{paddingBottom:0, marginBottom:0}}> <MobileLinks/><p style={{fontSize:'2em'}}>3RD STUDIO SINGLE <b>"TRIGGER" </b> </p>
                <p> Streaming on all major platforms now!</p>
                <br></br>
                 <p style={{fontSize:'1.25em'}}> <i>“Trigger” hits hard from the start with huge guitars and powerful vocals keeping a strong energy throughout. The track was written by Cameron (Vocals/E. Guitars) and Hamish (Vocals/Ac. Guitar) and has been a fan favourite from the start. </i></p>
                 <br></br>
                 <p> Click image to listen now</p>
                 </td>
                <td style={{height:'100%', width:'auto'}}> <div style={{position: 'relative', textAlign: 'left', color: 'white'}}>
                    <a href="https://tiny.cc/NDSpotify"> <img id='artwork' src={DeskLogo} style={{width: '100%'}} alt='Ninth Degree Cover'  /> </a>
                   </div>
                </td>
                </tr>
            </tbody>
    </Table>
    </div>
   </div>
  );
}

export default HomeImage;