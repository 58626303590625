import React from 'react';
//import logo from './AP1_4531.jpg';
import logo from './DSC_0103.jpg';

function AboutImage() {

  return (
    <div style={{position: 'relative', textAlign: 'center', color: 'white'}}>
    <img src={logo} style={{width: '100%', paddingBottom:20}} alt='Ninth Degree Cover' />
    </div>
  );
}

export default AboutImage;