import React, { Component } from 'react';
import logo from './Messenger_Logo_White_RGB.png';
class ContactPage extends Component {    

    render(){
        return(
            <div style={{paddingBottom:'5%'}}>
                <table class="table table-borderless">
                <tbody>
                    <tr>
                        <td style={{color:'white'}}>
                        <p style={{fontSize:'2em', padding:0, margin:0}} ><b>Contact Us</b></p>
                        </td>
                    </tr>
                    <tr style={{marginTop:0}}>
                        <td style={{color:'white'}}>
                            Email- info@ninthdegree.co.uk
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" class="btn btn-primary"> <b>Connect with us on Messenger </b> 
                            <a href="https://m.me/NinthDegreeUK"><img src={logo} alt="Spotify" style={{height:70, padding:10}}/></a>
                            </button>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        )
    }
}


export default ContactPage;