import React from 'react';
import { Table } from 'react-bootstrap';
import spotify from './Spotify_Logo_RGB_Green.png';
import youtube from './yt_logo_rgb_dark.png';
import facebook from './f_logo_RGB-Blue_1024op.png';
import instagram from './iglogoop.png';
import twitter from './Twitter_Logo_Blue.png' 
import './links.css';
function MyLinks(){
        return (
            <div style={{paddingBottom:'0%'}}>
            <Table striped bordered hover variant="dark" style={{ margin: 'auto', width: '100%', backgroundColor:'#1f1f1f'}}>
            <tbody>
                <tr>
                <td><a href="https://open.spotify.com/artist/5Bxxy1zuvMKKPYzDOA27Ak?si=iIoeUJAKQ063tpQWvGxRTA"><img src={spotify} id='s' alt="Spotify" style={{width:'8vw'}}/></a></td>
                <td><a href="https://www.youtube.com/channel/UC8ff8Ky_eyWIjEBEvalk2og"><img src={youtube} id='yt' alt="YouTube" style={{height:'auto', width:'12vw'}}/></a></td>
                <td><a href="https://www.facebook.com/NinthDegreeUK/"><img src={facebook} alt="Facebook" id='f' style={{width:'8vw'}}/></a></td>
                <td><a href="https://www.instagram.com/ninthdegreeuk/"><img src={instagram} alt="Instagram" id='i' style={{width:'8vw'}}/></a></td>
                <td><a href="https://twitter.com/ninthdegreeuk"><img src={twitter} alt="Twitter" id='i' style={{width:'10vw'}}/></a></td>
                </tr>
            </tbody>
            </Table>
            </div>
        );
}

export default MyLinks;