import React from 'react';
import logo from './AP1_4595-3.jpg';

function ContactImage() {

  return (
    <div style={{position: 'relative', textAlign: 'center', color: 'white'}}>
    <img src={logo} style={{width: '100%', paddingBottom:2}} alt='Ninth Degree Cover' />
    </div>
  );
}

export default ContactImage;