import React from 'react';
import { Table } from 'react-bootstrap';
import spotify from './Spotify_Logo_RGB_Green.png';
import youtube from './yt_logo_rgb_dark.png';
import facebook from './f_logo_RGB-Blue_1024op.png';
import instagram from './iglogoop.png';
import twitter from './Twitter_Logo_Blue.png' 

function MobileLinks(){
        return (
            <div style={{paddingBottom:'0%'}}>
            <Table variant="dark" style={{width: '100%', backgroundColor:'#1f1f1f'}}>
            <tbody>
                <tr>
                <td style={{width:'20%'}} ><a href="https://open.spotify.com/artist/5Bxxy1zuvMKKPYzDOA27Ak?si=iIoeUJAKQ063tpQWvGxRTA"><img src={spotify} id='s' alt="Spotify" style={{width:'80%'}}/></a></td>
                <td style={{width:'20%'}}><a href="https://www.facebook.com/NinthDegreeUK/"><img src={facebook} alt="Facebook" id='f' style={{width:'75%'}}/></a></td>
                <td style={{width:'20%'}}><a href="https://www.instagram.com/ninthdegreeuk/"><img src={instagram} alt="Instagram" id='i' style={{width:'75%'}}/></a></td>
                <td style={{width:'20%'}}><a href="https://twitter.com/ninthdegreeuk"><img src={twitter} alt="Twitter" id='i' style={{width:'95%'}}/></a></td>
                <td style={{width:'20%'}}><a href="https://www.youtube.com/channel/UC8ff8Ky_eyWIjEBEvalk2og"><img src={youtube} id='yt' alt="YouTube" style={{ paddingTop:'5%', width:'100%'}}/></a></td>
                </tr>
            </tbody>
            </Table>
            </div>
        );
}

export default MobileLinks;