import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const Footer = () => {
  return (
    <MDBFooter className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-center">
            <p>
                Website designed by Cameron Watt using React.js
            </p>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.ninthdegree.co.uk"> ninthdegree.co.uk </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;