import React, { Component } from 'react';
import MyBio from './MyBio';
import BandMembersImage from './BandMembersImage';

class AboutPage extends Component {
    render(){
        return(
            <div style={{paddingBottom:'5%'}}> 
                <MyBio/>
                <BandMembersImage/>
                <p style={{fontSize:'2em'}}><b>Band Members</b></p>
                <table class="table table-borderless" >
                <tbody>
                    <tr>
                        <td style={{color:'white'}}>
                        <a href="https://www.cwaudio.co.uk/" style={{color: "white"}}><b>Cameron Watt</b></a>- Vocals/E.Guitar
                        </td>
                    </tr>
                    <tr>
                        <td style={{color:'white'}}>
                            Connor Lilliott- Vocals/Bass
                        </td>
                    </tr>
                    <tr>
                        <td style={{color:'white'}}>
                            Hamish Misselbrook- Vocals/Keys/Ac.Guitar   
                        </td>
                    </tr>
                    <tr>
                        <td style={{color:'white'}}>
                            Joe Redman- Rhythm Cowbell/Drums
                        </td>
                    </tr>
                    <tr>
                        <td style={{color:'white'}}>
                            Reece Carr- Lead Cowbell/E.Guitar
                        </td>
                    </tr>
                </tbody>
                </table>  
            </div>
            
        )
    }
}

export default AboutPage;