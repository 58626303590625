import React from 'react';
import './App.css';
import AboutImage from './components/AboutImage';
import MyLinks from './components/MyLinks';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactImage from './components/ContactImage';
import Footer from './components/Footer';
import { Navbar, Nav, Button } from 'react-bootstrap';
import logo from './components/TransparentLogo.ico';
import HomePage from './components/HomePage';
import ndlogo from './ndlogo.png';

class App extends React.Component {


  linkToMerch() {
    window.location.href='https://ninthdegreeuk.bigcartel.com'
}

linkToMusic() {
    window.location.href='https://ninthdegreeuk.bandcamp.com/'
}

scrollToAbout(){
    window.location.href = "#about" 
}

scrollToContact(){
  window.location.href = "#contact"
}

scrollToTop(){
    window.scrollTo(0, 0);
}

  render(){
    return (
      <div className="App" style={{float:'left'}}>
        <header className="App-header">
          <div>
            <Navbar collapseOnSelect fixed="top" expand="lg" variant="dark" style={{padding: '0.5rem', backgroundColor:'#1f1f1f' }}>
              <Navbar.Brand href="#home" onClick={this.scrollToTop} ><img src= {logo} style={{height: '4rem' }} ></img></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                  <Nav.Link eventKey="2"> <Button variant="dark" onClick={this.scrollToAbout} style={{fontSize: 'calc(10px + 2vmin)', backgroundColor:'#1f1f1f', borderColor:'#1f1f1f' }}>About</Button></Nav.Link>
                  <Nav.Link eventKey="3"> <Button variant="dark" onClick={this.linkToMerch} style={{fontSize: 'calc(10px + 2vmin)', backgroundColor:'#1f1f1f', borderColor:'#1f1f1f'}}>Merch</Button></Nav.Link>
                  <Nav.Link eventKey="3"> <Button variant="dark" onClick={this.linkToMusic} style={{fontSize: 'calc(10px + 2vmin)', backgroundColor:'#1f1f1f', borderColor:'#1f1f1f'}}>Music</Button></Nav.Link>
                  <Nav.Link eventKey="4"> <Button variant="dark" onClick={this.scrollToContact} style={{fontSize: 'calc(10px + 2vmin)', backgroundColor:'#1f1f1f', borderColor:'#1f1f1f'}}>Contact</Button></Nav.Link>
                  <Nav.Link eventKey="1"> <Button id='home' variant="dark" style={{fontSize: 'calc(5px + 2vmin)', backgroundColor:'#1f1f1f', borderColor:'#1f1f1f'}}><i>-Hide Menu-</i></Button></Nav.Link>
              </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div id="links">
            <div id="mobilehome">
            <MyLinks/>
            </div>
            <HomePage/>
            <div id='about'>
            </div>
            <AboutImage/>
            <AboutPage/>
          
            <div id='contact'>
            <ContactImage/>
            <ContactPage/>

            </div>
          </div>

          <footer style={{backgroundColor:'#1f1f1f', width:'100%'}}>
            <Footer/>
          </footer>
        </header>

      </div>
    );
  }
}

export default App;
