import React from 'react';
import logo from './AP1_4613-2.jpg';

function BandMembersImage() {

  return (
    <div style={{position: 'relative', textAlign: 'center', color: 'white'}}>
    <img src={logo} style={{width: '100%', paddingBottom:20}} alt='Ninth Degree Cover' />
    </div>
  );
}

export default BandMembersImage;